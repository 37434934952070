import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { DICTIONARY_SCHEMA, type Locale } from "@/plugins/i18n";

const localeToFullName = new Map<Locale, string>()
  .set("en", "English")
  .set("ua", "Українська");

export const useLocalization = () => {
  const { locale, availableLocales, t } = useI18n();

  const languageNames = computed(() =>
    availableLocales.map((availableLocale) => ({
      locale: availableLocale as Locale,
      name: localeToFullName.get(availableLocale as Locale),
    }))
  );

  const setLocale = async (value: Locale, skipSave = false): Promise<void> => {
    locale.value = value;

    if (skipSave) {
      return;
    }

    localStorage.setItem("language", value);
  };

  return {
    languageNames,
    locale,
    availableLocales,
    dict: DICTIONARY_SCHEMA,
    setLocale,
    t,
  };
};
