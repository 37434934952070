<template>
  <svg
    class="arrow-right-icon"
    width="101"
    height="30"
    viewBox="0 0 101 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100.203 12.7208C99.9865 12.7208 100.024 12.6544 99.8213 12.6148C99.2793 12.5088 98.3604 12.1908 97.3848 11.8728C95.4336 11.1307 93.0488 9.96466 91.206 8.05654C89.3631 6.25442 88.1707 4.24028 87.5203 2.65018C87.1951 1.80212 86.9783 1.16608 86.7615 0.636043C86.6531 0.424029 86.6531 0.212015 86.6531 0.106007C86.6531 4.09782e-07 86.6531 0 86.6531 0C86.6531 0 86.6531 1.78814e-07 85.0271 0.318021C83.4011 0.636043 83.4011 0.636043 83.4011 0.636043C83.4011 0.636043 83.4011 0.636043 83.4011 0.74205C83.4011 0.74205 83.4011 0.848057 83.4011 0.954064C83.4011 1.16608 83.5095 1.37809 83.6179 1.69611C83.8347 2.33216 84.0515 3.07421 84.4851 4.02827C85.3523 5.9364 86.6531 8.37456 88.9295 10.4947C89.7967 11.3428 90.7723 12.0848 91.748 12.7208H0V16.9611H91.9648C90.8808 17.5972 89.7967 18.4452 88.7127 19.5053C86.5447 21.6254 85.1355 24.1696 84.2683 25.9717C83.8347 26.9258 83.6179 27.7739 83.4011 28.3039C83.2927 28.6219 83.2927 28.8339 83.1843 29.0459C83.1843 29.1519 83.1843 29.1519 83.1843 29.2579C83.1843 29.2579 83.1843 29.258 83.1843 29.364C83.1843 29.364 83.1843 29.364 84.8103 29.682C86.4363 30 86.4363 30 86.4363 30V29.894C86.4363 29.788 86.5447 29.576 86.5447 29.364C86.6531 28.8339 86.9783 28.1979 87.3035 27.3498C88.0623 25.7597 89.1463 23.6396 90.9892 21.9435C92.832 20.0353 95.2168 18.8693 97.168 18.1272C98.1436 17.8092 99.0108 17.4912 99.5528 17.3852C99.878 17.2792 100.095 17.2792 100.203 17.2792V16.3251V13.8869V12.7208Z"
    />
  </svg>
</template>
