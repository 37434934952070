import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18nFactory } from "@/plugins/i18n";
import { components } from "@/components/components";
import { createPinia } from "pinia";
import { initializeApp } from "firebase/app";
import VueConfetti from "vue-confetti";

const pinia = createPinia();

const firebaseConfig = {
  apiKey: "AIzaSyA_salLD0MM-1BIcZ0ZTggcH-DLWP2JAt8",
  authDomain: "onlysense-app.firebaseapp.com",
  projectId: "onlysense-app",
  storageBucket: "onlysense-app.appspot.com",
  messagingSenderId: "826154733070",
  appId: "1:826154733070:web:715f683fe071cd2764365f",
};

initializeApp(firebaseConfig);

createApp(App)
  .use(i18nFactory("en"))
  .use(pinia)
  .use(store)
  .use(router)
  .use(components)
  .use(VueConfetti as any)
  .mount("#app");
