import { createStore } from "vuex";

export default createStore({
  state: {
    language: "en",
    theme: "light",
  },
  getters: {
    isDarkTheme: (state) => state.theme === "dark",
    isLightTheme: (state) => state.theme === "light",
  },
  mutations: {
    setLocal(state, payload) {
      state.language = payload;
      localStorage.setItem("language", payload);
      localStorage.getItem("language");
    },
    setTheme(state, payload) {
      state.theme = payload;
      localStorage.setItem("theme", payload);
    },
  },
  actions: {},
  modules: {},
});
