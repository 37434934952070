<template>
  <div class="form__bottom">
    <div class="form__bottom_btn" @click="$emit('event')">
      <span>{{ t(dict.FORM.SAVE) }}</span>
    </div>
  </div>
</template>

<script setup>
import { useLocalization } from "@/plugins/i18n";

const { t, dict } = useLocalization();
</script>
