import axios from "axios";
import { registerInterceptors } from "@/shared/http/interceptors";

// import { registerInterceptors } from "@core/http/interceptors";

export const Headers = {
  CONTENT_TYPE: "Content-type",
  LANG: "lang",
  AUTHORIZATION: "Authorization",
  ACCEPT: "Accept",
};

export const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: process.env.VUE_APP_REQUEST_TIMEOUT,
  withCredentials: true,
  headers: {
    [Headers.CONTENT_TYPE]: "application/json; charset=UTF-8",
    [Headers.ACCEPT]: "application/json",
  },
});

registerInterceptors(api);
