import { en } from "@/plugins/i18n";

interface Node {
  [key: string]: Node | string;
}

const concatIfExistsPath = (path: string, suffix: string): string => {
  return path ? `${path}.${suffix}` : suffix;
};

const transformObjectToPath = <T extends Node | string>(
  suffix: string,
  objectToTransformOrEndOfPath: T,
  path = ""
): T => {
  return typeof objectToTransformOrEndOfPath === "object"
    ? Object.entries(objectToTransformOrEndOfPath).reduce(
        (objectToTransform, [key, value]) => {
          const nestedObject = objectToTransform as Node;

          nestedObject[key] = transformObjectToPath(
            key,
            value,
            concatIfExistsPath(path, suffix)
          );

          return objectToTransform;
        },
        {} as T
      )
    : (concatIfExistsPath(path, suffix) as T);
};

export const DICTIONARY_SCHEMA = transformObjectToPath("", en);
