import { type App } from "vue";

import AnimateLineText from "@/components/animate-line-text.vue";
import Toggle from "@/components/toggle.vue";
import Logo from "@/components/logo.vue";
import Arrow from "@/components/arrow.vue";
import ThemeToggle from "@/components/theme-toggle.vue";
import AIChat from "@/components/ai-chat.vue";
import Label from "@/components/label.vue";
import SmallArrowRightIcon from "@/components/small-arrow-right-icon.vue";
import ArrowRightIcon from "@/components/arrow-right-icon.vue";
import Field from "@/components/field.vue";
import FormBottom from "@/components/form-bottom.vue";
import SliderArrowLeft from "@/components/slider-arrow-left.vue";
import SliderArrowRight from "@/components/slider-arrow-right.vue";
import LanguageSwitcher from "@/components/language-switcher.vue";

const components = {
  install: (app: App<Element>) => {
    app.component("AnimateLineText", AnimateLineText);
    app.component("Toggle", Toggle);
    app.component("Logo", Logo);
    app.component("Arrow", Arrow);
    app.component("ThemeToggle", ThemeToggle);
    app.component("AIChat", AIChat);
    app.component("OnlysenseLabel", Label);
    app.component("SmallArrowRightIcon", SmallArrowRightIcon);
    app.component("ArrowRightIcon", ArrowRightIcon);
    app.component("OnlysenseField", Field);
    app.component("FormBottom", FormBottom);
    app.component("SliderArrowLeft", SliderArrowLeft);
    app.component("SliderArrowRight", SliderArrowRight);
    app.component("LanguageSwitcher", LanguageSwitcher);

    // app.use(VueConfetti as any);
  },
};

export { components };
