import { createI18n, type I18n } from "vue-i18n";

// import '@core/i18n/dayjs-init';

import en from "./translations/en.json";
import ua from "./translations/ua.json";
import ru from "./translations/ru.json";

export type DictionarySchema = typeof en;
export type Locale = "en" | "ua" | "ru";

let i18n: I18n;

const i18nFactory = (locale: Locale) => {
  i18n = createI18n<[DictionarySchema], "en" | "ua" | "ru">({
    locale: locale || "en",
    fallbackLocale: locale || "en",
    legacy: false,
    messages: {
      en,
      ua,
      ru,
    },
  });

  return i18n;
};

export { i18nFactory, i18n, en, ua, ru };
export * from "@/plugins/i18n/use-localization";
export * from "@/plugins/i18n/translations-dictionary.provider";
