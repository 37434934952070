import { type AxiosRequestConfig } from "axios";
import { api, Headers } from "@/shared/http";

type RequestOptions = Pick<
  AxiosRequestConfig,
  "url" | "method" | "data" | "params" | "headers"
> & {
  abortController?: AbortController;
};

export const Http = {
  async request<T>({
    url,
    method,
    data,
    params,
    headers,
    abortController,
  }: RequestOptions) {
    return api.request<T>({
      url,
      method,
      data,
      params,
      headers: Http.addFormDataHeader(data, headers),
      signal: abortController?.signal,
    } as AxiosRequestConfig);
  },

  async get<T>({
    url,
    params,
    headers,
    abortController,
  }: Omit<RequestOptions, "method" | "data">) {
    return Http.request<T>({
      method: "GET",
      url,
      params,
      headers,
      abortController,
    });
  },

  async post<T>({
    url,
    data,
    params,
    headers,
    abortController,
  }: Omit<RequestOptions, "method">) {
    return Http.request<T>({
      method: "POST",
      url,
      data,
      params,
      headers,
      abortController,
    });
  },

  async put<T>({
    url,
    data,
    params,
    headers,
    abortController,
  }: Omit<RequestOptions, "method">) {
    return Http.request<T>({
      method: "PUT",
      url,
      data,
      params,
      headers,
      abortController,
    });
  },

  async delete<T>({
    url,
    params,
    headers,
    abortController,
  }: Omit<RequestOptions, "method" | "data">) {
    return Http.request<T>({
      method: "DELETE",
      url,
      params,
      headers,
      abortController,
    });
  },

  addFormDataHeader<T>(
    data: T,
    headers: RequestOptions["headers"]
  ): RequestOptions["headers"] {
    return {
      ...(data instanceof FormData
        ? { [Headers.CONTENT_TYPE]: "multipart/form-data" }
        : {}),
      ...(headers || {}),
    } as RequestOptions["headers"];
  },
};
