import type { AxiosResponse } from "axios";
import { Http } from "@/shared/http/http";
import { BaseApiResponse } from "@/shared/http/models/base-reponse";

const SessionApi = () => ({
  me: "/auth/me",
  refreshToken: `/refresh`,
  logout: `/auth/logout`,
});

const SessionApiConnectorFactory = () => {
  const endpoints = SessionApi();

  const refreshToken = (): Promise<AxiosResponse<any>> => {
    return Http.post({
      url: endpoints.refreshToken,
    });
  };

  const logout = (): Promise<AxiosResponse<BaseApiResponse>> => {
    return Http.post<BaseApiResponse>({
      url: endpoints.logout,
    });
  };

  const getUser = (): Promise<AxiosResponse<any>> => {
    return Http.get({
      url: endpoints.me,
    });
  };

  return {
    logout,
    refreshToken,
    getUser,
  };
};

export const SessionApiConnector = SessionApiConnectorFactory();
