import { Headers } from "@/shared/http";
import { InternalAxiosRequestConfig } from "axios";
import { useCookies } from "vue3-cookies";

export const accessTokenInterceptor = async (
  requestConfig: InternalAxiosRequestConfig
) => {
  const cookies = useCookies();

  const accessToken =
    cookies.cookies.get("access_token") || cookies.cookies.get("refresh_token");

  if (accessToken) {
    requestConfig.headers[Headers.AUTHORIZATION] = accessToken;
  }

  return requestConfig;
};
