import { AxiosRequestConfig } from "axios";
import { ResponseInterceptor } from "@/shared/http/interceptors/index";
import { api } from "@/shared/http";
import { useSessionStore } from "@/modules/session/store";
import { useCookies } from "vue3-cookies";
import { SessionApiConnector } from "@/modules/session/services/session-api-connector";

let hasError = false;

export const refreshTokenInterceptor: ResponseInterceptor = {
  success: (response) => response,
  error: async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !hasError) {
      hasError = true;

      const cookies = useCookies();
      const sessionStore = useSessionStore();

      if (cookies.cookies.get("refresh_token")) {
        try {
          const { data } = await SessionApiConnector.refreshToken();

          hasError = false;

          await sessionStore.setTokens(data);

          return api(originalRequest as AxiosRequestConfig);
        } catch (e) {
          hasError = false;

          await sessionStore.logout(true);

          return Promise.reject(error);
        }
      }
    }

    return Promise.reject(error);
  },
};
