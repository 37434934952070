import type { AxiosResponse } from "axios";
import { Http } from "@/shared/http/http";
import { BaseApiResponse } from "@/shared/http/models/base-reponse";

const CabinetApi = () => ({
  email: "/auth/email",
  emailConfirm: "/auth/email/confirm",
  name: "/auth/name",
  phone: "/auth/phone",
  courses: "/auth/courses",
  getLastViewedVideo: "/auth/content/last_viewed",
  saveLastViewedVideo: "/auth/content/last_viewed",
  deleteFavoriteVideo: "/auth/content/favorite",
  getFavoriteUserVideos: "/auth/content/favorites",
  addVideoToFavorite: "/auth/content/favorite",
  changeFavVideosPriorities: "/auth/content/favorite/priorities",
  getLesson: "/auth/lesson",
  getContent: "/auth/content",
  getCourseLvlLessonsUser: "/auth/course/:id/lessons",
  createThread: "/auth/thread",
  getThread: "/auth/threads",
  deleteMessage: "/auth/thread/message/:thread_id/:message_id",
  editMessage: "/auth/message",
  addMessage: "/auth/message",
  saveDM: "/auth/dm",
  editDM: "/auth/dm",
  getAllDMs: "/auth/dms",
  getDMByID: "/auth/dm",
  saveFeedbackMessage: "/auth/feedback",
  getFeedbackThreads: "/auth/feedback",
  getFeedbackThreadByID: "/auth/feedback",
  getVdocipherIframeData: process.env.VUE_APP_API_MEDIA_URL + "/new-media/otp",
});

const CabinetApiConnectorFactory = () => {
  const endpoints = CabinetApi();

  const changeName = (name: string): Promise<AxiosResponse<any>> => {
    return Http.put<BaseApiResponse>({
      url: endpoints.name,
      data: {
        name,
      },
    });
  };

  const getVdocipherIframeData = (params: {
    [key: string]: string;
  }): Promise<AxiosResponse<any>> => {
    const paramsString = new URLSearchParams(params).toString();

    console.log("paramsString", paramsString);

    return Http.get<BaseApiResponse>({
      url: `${endpoints.getVdocipherIframeData}?${paramsString}`,
    });
  };

  const getAllDMs = (): Promise<AxiosResponse<any>> => {
    return Http.get({
      url: endpoints.getAllDMs,
    });
  };
  const getDMByID = (dm_id: number | string): Promise<AxiosResponse<any>> => {
    return Http.get({
      url: `${endpoints.getDMByID}/${dm_id}`,
    });
  };
  const saveDM = (
    user_id: number,
    message: string
  ): Promise<AxiosResponse<any>> => {
    return Http.post<BaseApiResponse>({
      url: endpoints.saveDM,
      data: {
        user_id,
        message,
      },
    });
  };
  const editDM = (
    tread_id: number,
    user_id: number,
    message: string,
    message_id: number
  ): Promise<AxiosResponse<any>> => {
    return Http.put<BaseApiResponse>({
      url: endpoints.editDM,
      data: {
        user_id,
        message,
        message_id,
        tread_id,
      },
    });
  };

  const getFeedbackThreads = (): Promise<AxiosResponse<any>> => {
    return Http.get({
      url: endpoints.getFeedbackThreads,
    });
  };
  const getFeedbackThreadByID = (
    feedback_id: number | string
  ): Promise<AxiosResponse<any>> => {
    return Http.get({
      url: `${endpoints.getFeedbackThreadByID}/${feedback_id}`,
    });
  };
  const saveFeedbackMessage = (
    course_lvl_id: number,
    message: string
  ): Promise<AxiosResponse<any>> => {
    return Http.post({
      url: endpoints.saveFeedbackMessage,
      data: {
        course_lvl_id,
        message,
      },
    });
  };

  const createThread = (
    content_id: number,
    message: string
  ): Promise<AxiosResponse<any>> => {
    return Http.post<BaseApiResponse>({
      url: endpoints.createThread,
      data: {
        content_id,
        message,
      },
    });
  };

  const editMessage = (
    content_id: number,
    thread_id: string,
    message_id: string,
    message: string
  ): Promise<AxiosResponse<any>> => {
    return Http.put<BaseApiResponse>({
      url: endpoints.editMessage,
      data: {
        content_id,
        thread_id,
        message_id,
        message,
      },
    });
  };

  const addMessage = (
    content_id: number,
    thread_id: string,
    message: string
  ): Promise<AxiosResponse<any>> => {
    return Http.post<BaseApiResponse>({
      url: endpoints.addMessage,
      data: {
        content_id,
        thread_id,
        message,
      },
    });
  };

  const getThread = (
    content_id: string | number
  ): Promise<AxiosResponse<any>> => {
    return Http.get<BaseApiResponse>({
      url: `${endpoints.getThread}/${content_id}`,
    });
  };

  const deleteMessage = (
    thread_id: string,
    message_id: string
  ): Promise<AxiosResponse<any>> => {
    return Http.delete<BaseApiResponse>({
      url: endpoints.deleteMessage
        .replace(":thread_id", thread_id)
        .replace(":message_id", message_id),
    });
  };

  const changeFavVideosPriorities = (
    videos: any
  ): Promise<AxiosResponse<any>> => {
    return Http.put<BaseApiResponse>({
      url: endpoints.changeFavVideosPriorities,
      data: {
        videos,
      },
    });
  };

  const deleteVideoFromFavorites = (
    id: string | number
  ): Promise<AxiosResponse<any>> => {
    return Http.delete<BaseApiResponse>({
      url: `${endpoints.deleteFavoriteVideo}/${id}`,
    });
  };

  const getCoursesStudent = (): Promise<AxiosResponse<any>> => {
    return Http.get({
      url: endpoints.courses,
    });
  };

  const getCourseLvlLessonsUser = (id: any): Promise<AxiosResponse<any>> => {
    return Http.get({
      url: endpoints.getCourseLvlLessonsUser.replace(":id", id),
    });
  };

  const getLesson = (id: string | number): Promise<AxiosResponse<any>> => {
    return Http.get({
      url: `${endpoints.getLesson}/${id}`,
    });
  };

  const getContent = (id: string | number): Promise<AxiosResponse<any>> => {
    return Http.get({
      url: `${endpoints.getContent}/${id}`,
    });
  };

  const getLastViewedVideo = (): Promise<AxiosResponse<any>> => {
    return Http.get({
      url: endpoints.getLastViewedVideo,
    });
  };

  const getFavorite = (): Promise<AxiosResponse<any>> => {
    return Http.get({
      url: endpoints.getFavoriteUserVideos,
    });
  };

  const changePhone = (phone: string): Promise<AxiosResponse<any>> => {
    return Http.put<BaseApiResponse>({
      url: endpoints.phone,
      data: {
        phone,
      },
    });
  };

  const saveLastViewedVideo = (id: number): Promise<AxiosResponse<any>> => {
    return Http.post<BaseApiResponse>({
      url: endpoints.saveLastViewedVideo,
      data: {
        content_id: id,
      },
    });
  };

  const addVideoToFavorite = (id: number): Promise<AxiosResponse<any>> => {
    return Http.post<BaseApiResponse>({
      url: endpoints.addVideoToFavorite,
      data: {
        content_id: id,
      },
    });
  };

  const changeEmail = (email: string): Promise<AxiosResponse<any>> => {
    return Http.put<BaseApiResponse>({
      url: endpoints.email,
      data: {
        email,
      },
    });
  };

  const confirmEmail = (
    email: string,
    code: string
  ): Promise<AxiosResponse<any>> => {
    return Http.put<BaseApiResponse>({
      url: endpoints.emailConfirm,
      data: {
        email,
        code,
        type_id: 1, // student
      },
    });
  };

  return {
    changeName,
    changeEmail,
    confirmEmail,
    changePhone,
    getCoursesStudent,
    deleteVideoFromFavorites,
    getLastViewedVideo,
    getFavorite,
    changeFavVideosPriorities,
    getLesson,
    getContent,
    saveLastViewedVideo,
    getCourseLvlLessonsUser,
    addVideoToFavorite,
    createThread,
    getThread,
    deleteMessage,
    addMessage,
    editMessage,
    saveDM,
    editDM,
    getAllDMs,
    getDMByID,
    saveFeedbackMessage,
    getFeedbackThreads,
    getFeedbackThreadByID,
    getVdocipherIframeData,
  };
};

export const CabinetApiConnector = CabinetApiConnectorFactory();
