import { accessTokenInterceptor } from "@/shared/http/interceptors/access-token.interceptor";
import { refreshTokenInterceptor } from "@/shared/http/interceptors/refresh-token.interceptor";
import {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

export type RequestInterceptor = (
  requestConfig: InternalAxiosRequestConfig
) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>;
export type ResponseInterceptor = {
  success: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>;
  error: (response: AxiosError) => AxiosError | Promise<AxiosError>;
};

const requestInterceptors: Array<RequestInterceptor> = [accessTokenInterceptor];
const responseInterceptors: Array<ResponseInterceptor> = [
  refreshTokenInterceptor,
];

export const registerInterceptors = (api: AxiosInstance) => {
  for (const interceptor of requestInterceptors) {
    api.interceptors.request.use(interceptor);
  }

  for (const interceptor of responseInterceptors) {
    api.interceptors.response.use(interceptor.success, interceptor.error);
  }
};
