import { defineStore } from "pinia";
import { SessionApiConnector } from "@/modules/session/services/session-api-connector";
import { useCookies } from "vue3-cookies";
import { TokensResp } from "@/shared/http/models/tokens.resp";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { useStatePatcher } from "@/shared/composables/use-state-pathcer";
import { getAuth, signOut } from "firebase/auth";

export interface SessionState {
  user: Record<string, any> | null;
}

export const useSessionStore = defineStore("session", () => {
  const state = ref<SessionState>({
    user: null,
  });

  const patchState = useStatePatcher<SessionState>(state);

  const cookies = useCookies();
  const router = useRouter();

  const setTokens = (data: TokensResp) => {
    cookies.cookies.set(
      "access_token",
      data.access_token,
      +((data.access_expired * 1000 - Date.now()) / 1000).toFixed(0),
      undefined,
      undefined,
      true
    );

    cookies.cookies.set(
      "refresh_token",
      data.refresh_token,
      +((data.refresh_expired * 1000 - Date.now()) / 1000).toFixed(0),
      undefined,
      undefined,
      true
    );
  };

  const refreshToken = async () => {
    try {
      const { data } = await SessionApiConnector.refreshToken();

      await setTokens(data);
    } catch (e) {
      console.error(e, "Session:refreshToken");
    }
  };

  const getUser = async () => {
    try {
      const { data } = await SessionApiConnector.getUser();

      patchState({
        user: data,
      });
    } catch (e) {
      console.error(e, "Session:getUser");
    }
  };

  const logout = async (isHardLogout = false) => {
    try {
      const cookies = useCookies();
      if (!isHardLogout) {
        const access_token =
          cookies.cookies.get("access_token") &&
          cookies.cookies.get("refresh_token");
        if (access_token.includes("Bearer")) {
          await SessionApiConnector.logout();
        } else {
          const auth = getAuth();
          await signOut(auth);
        }
      }

      patchState({
        user: null,
      });

      cookies.cookies.remove("access_token");
      cookies.cookies.remove("refresh_token");

      await router.push({ name: "login" });
    } catch (e) {
      console.error(e, "Session:logout");
    }
  };

  return {
    state,
    getUser,
    refreshToken,
    logout,
    setTokens,
  };
});
