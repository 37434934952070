<template>
  <svg
    class="small-arrow-right-icon"
    width="31"
    height="21"
    viewBox="0 0 31 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.1787 8.57627C30.0348 8.57627 30.0595 8.53198 29.9252 8.5056C29.5655 8.43493 28.9557 8.22291 28.3082 8.0109C27.0132 7.5162 25.4305 6.73881 24.2075 5.46673C22.9845 4.26531 22.1931 2.92256 21.7614 1.86249C21.5456 1.29712 21.4017 0.873089 21.2578 0.519732C21.1859 0.378389 21.1859 0.237046 21.1859 0.166375C21.1859 0.0957034 21.1859 0.0957031 21.1859 0.0957031C21.1859 0.0957031 21.1859 0.0957032 20.1068 0.307717C19.0276 0.519732 19.0276 0.519732 19.0276 0.519732C19.0276 0.519732 19.0276 0.519732 19.0276 0.590403C19.0276 0.590403 19.0276 0.661074 19.0276 0.731746C19.0276 0.873088 19.0996 1.01443 19.1715 1.22645C19.3154 1.65047 19.4593 2.14517 19.7471 2.78122C20.3226 4.0533 21.1859 5.67874 22.6967 7.09217C23.2722 7.65754 23.9197 8.15224 24.5672 8.57627H0.178711V11.4031H24.7111C23.9917 11.8272 23.2722 12.3925 22.5528 13.0992C21.114 14.5127 20.1787 16.2088 19.6032 17.4102C19.3154 18.0462 19.1715 18.6116 19.0276 18.965C18.9557 19.177 18.9557 19.3183 18.8837 19.4597C18.8837 19.5303 18.8837 19.5303 18.8837 19.601C18.8837 19.601 18.8837 19.601 18.8837 19.6717C18.8837 19.6717 18.8837 19.6717 19.9629 19.8837C21.042 20.0957 21.042 20.0957 21.042 20.0957V20.025C21.042 19.9544 21.114 19.813 21.114 19.6717C21.1859 19.3183 21.4017 18.8943 21.6176 18.3289C22.1212 17.2688 22.8406 15.8554 24.0636 14.7247C25.2866 13.4526 26.8694 12.6752 28.1643 12.1805C28.8118 11.9685 29.3873 11.7565 29.7471 11.6858C29.9629 11.6151 30.1068 11.6151 30.1787 11.6151V10.9791V9.35365V8.57627Z"
    />
  </svg>
</template>
